import { ElMessageBox, ElMessage } from "element-plus"
export const open = (data) => {
  const winWidth = document.body.clientWidth
  // console.log(winWidth);
  if (winWidth < 800) {
    ElMessageBox.confirm(
      '确定要拨打' + data + '的电话吗？',
      '拨打电话',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(() => {
      const a = document.createElement("a");
      a.href = "tel:" + data
      a.click()
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: 'Delete canceled',
      })
    })
  }
}

<template>
  <div class="headerBox">
    <div v-if="isLeft" class="backBtn" @click="backTo">
      <img :src="ossImgUrl('angle-left')" alt="">
    </div>
<!--    logo-->
    <div style="float: left; margin-top:6px; cursor: pointer;" @click="junpsToIndex">
      <img :src="ossImgUrl('logo')" alt="">
    </div>
    <!--    更多-->
    <div style="float: right; margin: 6px 0 0 40px; cursor: pointer;" @click="drawer = true">
      <img :src="ossImgUrl('more')" alt="" />
    </div>
<!--    联系电话-->
    <div class="PhoneBox" style="float: right;">
      <div style="float: left; margin:6px 20px 0 0;">
        <img style="width: 30px;" :src="ossImgUrl('weixin')" alt="">
      </div>
      <div @click="jumpsToWeixin" style="float: left; color:#fff;">
        <div style="font-size: 14px;">点击联系客服</div>
<!--        <div @click="cellPhone('0871-68888889')" style="font-size: 20px; font-weight: bold;">0871-6888 8889</div>-->
        <div>
          <span style="font-size: 14px; font-weight: bold; cursor: pointer; margin-top: 3px; border-bottom: 2px solid #ff7307; color: #ff7307;">在线客服</span>
        </div>
      </div>
    </div>
    <el-drawer
      v-model="drawer"
      :with-header="false">
      <div>
        <img :src="ossImgUrl('logo')" alt="">
      </div>
      <div class="menuList">
        <div class="menuItem" v-for="(menu, index) in menuList" :key="index">
          <div v-if="menu.children && menu.children.length > 0">
            <span>{{ menu.name }}</span>
            <div class="menuChildItem">
              <div v-for="(menuChild, indexChild) in menu.children" :key="indexChild">
                <span :class="{'activeMenu' : menuPath == menuChild.path}" @click="jumpsTo(menuChild.path)">{{ menuChild.name }}</span>
              </div>
            </div>
          </div>
          <div v-else><span :class="{'activeMenu' : menuPath == menu.path}" @click="jumpsTo(menu.path)">{{ menu.name }}</span></div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { menuList } from "@/utils/menu";
import { open } from "@/utils/phone"
import { ossImgUrl } from "@/utils/imgUrl";
import YSF from '@neysf/qiyu-web-sdk';

export default {
  name: "headerView",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const drawer = ref(false);
    const menuPath = ref(null)
    watch(() => route.path, (newValue) => {
      menuPath.value = newValue
    }, { immediate: true })
    const junpsToIndex = () => {
      router.push('/index')
    }
    const jumpsTo = (data) => {
      router.push(data)
      // menuPath.value = data
      drawer.value = false
    };
    const cellPhone = (data) => {
      open(data)
    }
    const isLeft = ref(null)
    router.beforeEach((to, from, next) => {
      next()
      if (window.sessionStorage.firstUrl.indexOf(to.path) > -1) { // === window.location.href
        isLeft.value = false
      } else {
        isLeft.value = true
      }
    })
    const backTo = () => {
      router.go(-1)
    }
    const jumpsToWeixin = () => {
      // window.open('https://work.weixin.qq.com/kfid/kfcf14ba2fb253c3391')
      YSF.init('845756a457451e0c0d5179ab37a3a921',{
        templateId: 123, // templateId表示自定义会话邀请的模板标识
        shuntId: 123, // shuntId表示访客选择多入口分流模版id
        sessionInvite: false, // sessionInvite表示是否开启会话邀请设置
        hidden: 1 // hidden表示是否隐藏访客端默认入口
      }).then(ysf => {
        ysf('open');
      }).catch(error => {
        console.log('sdk加载失败---', error);
      });
    }
    return {
      drawer,
      menuList,
      menuPath,
      junpsToIndex,
      jumpsTo,
      cellPhone,
      ossImgUrl,
      isLeft,
      backTo,
      jumpsToWeixin
    }
  },
};
</script>

<style scoped lang="less">
.headerBox {
  background: rgba(0, 0, 0, 0.5);
  padding: 15px 50px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  /deep/ .el-drawer__body {
    background: #262e35 !important;
  }
  .backBtn {
    display: none;
    line-height: 42px;
    margin-right: 20px;
    float: left;
    cursor: pointer;
    img {
      height: 20px;
      margin-top: 11px;
    }
    @media screen and (max-width: 740px) {
      display: block;
    }
  }
  @media screen and (max-width:767px) {
    padding: 10px 20px;

  }
}
.PhoneBox {
  @media screen and (max-width:767px) {
    display: none;
  }
}
.menuList {
  padding: 30px 0;
  font-size: 16px;
  color: #fff;
  line-height: 32px;
  span {
    cursor: pointer;
    display: block;
  }
  span:hover {
    color: #FFAB28 !important;
  }
  .menuItem {
    .menuChildItem {
      padding-left: 40px;
    }
  }
}
.activeMenu {
  color: #FFAB28 !important;
}
/deep/ .el-drawer {
  background: #262e35 !important;
  width: 400px !important;
  padding: 0 50px;
  @media screen and (max-width:1000px) {
    width: 200px !important;
    padding: 0;
  }
}
</style>

<template>
  <headerView />
  <router-view />
  <el-backtop :right="20" :bottom="20" />
</template>

<script>
import headerView from "@/components/headerView";
export default {
  components: {
    headerView,
  },
};
</script>

<style lang="less">
@import "@/assets/style/animate.min.css";
* {
  margin: 0;
}
body,html,#app {
  width: 100%;
  height: 100%;
  background: #fff;
}
.infoMoreBtn {
  float: right;
  color: #FF7307 !important;
  font-size: 16px;
}
.rightInfoBtn {
  .el-icon {
    color: #FF7307 !important;
  }
}

//从左向右溅出动画
.leftToRight {
  -webkit-animation: example 1s ease-out 1s backwards;
  animation: example 1s ease-out 1s backwards
}
.rightToLeft {
  -webkit-animation: example1 1s ease-out 1s backwards;
  animation: example1 1s ease-out 1s backwards
}
@-webkit-keyframes example {
  0% {
    -webkit-transform: translate(-1000px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate(-500px);
    opacity: .5;
  }
  100% {
    -webkit-transform: translate(0);
    opacity: 1;
  }
}
@keyframes example {
  0% {
    transform: translate(-1000px);
    opacity: 0;
  }
  50% {
    transform: translate(-500px);
    opacity: 0.5;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
@-webkit-keyframes example1 {
  0% {
    -webkit-transform: translate(1000px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translate(500px);
    opacity: .5;
  }
  100% {
    -webkit-transform: translate(0);
    opacity: 1;
  }
}
@keyframes example1 {
  0% {
    transform: translate(1000px);
    opacity: 0;
  }
  50% {
    transform: translate(500px);
    opacity: 0.5;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}
.chainTitle {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
  display: flex;
  .line {
    width: 50px;
    height: 6px;
    background: #ff7307;
    margin: 25px 0 0 30px;
  }
}
.englishTitle {
  font-size: 25px;
  color: #E5F0FE;
  line-height: 60px
}
.textMain {
  font-size: 18px;
  line-height: 36px;
  color: #E5F0FE;
  p {
    margin-top: 10px;
  }
}
@media screen and (max-width:800px) {
  .chainTitle {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    .line {
      margin: 10px 0 0 20px;
    }
  }
  .englishTitle {
    font-size: 18px;
    line-height: 36px
  }
  .textMain {
    font-size: 14px;
    line-height: 30px;
    color: #E5F0FE;
  }
}

</style>

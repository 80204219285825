export const menuList = [
  {
    name: "首页",
    path: "/index",
  },
  {
    name: "集团企业",
    path: "/dytz",
    isShow: false,
    children: [
      { name: "滇约投资", path: "/dytz" },
    ],
  },
  {
    name: "滇约产品",
    path: "/dycd",
    isShow: false,
    children: [
      { name: "滇约出行", path: "/dycx" },
      { name: "滇约充电", path: "/dycd" },
      { name: "滇约速运", path: "/dysy" },
      { name: "滇约客运", path: "/dyky" },
      { name: "旅游客运", path: "/dylyky" },
      // { name: "滇约山泉", path: "/dysq" },
      { name: "滇约商城", path: "/dyShop" },
      { name: "滇约检车", path: "/dyjc" },
      { name: "滇约驾考", path: "/dyjk" },
    ],
  },
  {
    name: "加盟合作",
    path: "/",
    isShow: false,
    children: [
      { name: "加盟滇约出行", path: "/joinDycx" },
      { name: "加盟滇约充电", path: "/joinDycd" },
      { name: "加盟滇约速运", path: "/joinDysy" },
      { name: "加盟滇约商城", path: "/joinShop" },
      { name: "加盟滇约旅游", path: "/joinDyly" },
      { name: "加盟滇约租车", path: "/joinDyzc" },
      { name: "加盟滇约驾考", path: "/joinDyjk" },
    ],
  },
  {
    name: "人才招聘",
    path: "/recruit",
  },
  {
    name: "新闻资讯",
    path: "/news",
  },
  {
    name: "社会价值",
    path: "/social",
  },
  {
    name: '智慧出行联盟',
    path: '/cooperativeAlliance'
  },
  {
    name: '附近门店',
    path: '/nearbyStores'
  }
]

import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/index.vue"),
    meta: {
      title: "滇约投资集团有限公司",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../components/homeView.vue"),
    children: [
      {
        path: "/newsInfo",
        name: "newsInfo",
        component: () => import("../views/components/newsInfo.vue"),
        meta: {
          title: "首页新闻详情",
        },
      },
      // {
      //   path: "/dysq",
      //   name: "dysq",
      //   component: () => import("../views/dysq/index.vue"),
      //   meta: {
      //     title: "滇约山泉",
      //   },
      // },
      {
        path: "/dycd",
        name: "dycd",
        component: () => import("../views/dycd/index.vue"),
        meta: {
          title: "滇约充电",
        },
      },
      {
        path: "/dyShop",
        name: "dyShop",
        component: () => import("../views/dyShop/index.vue"),
        meta: {
          title: "滇约商城",
        },
      },
      {
        path: "/dyjk",
        name: "dyjk",
        component: () => import("../views/dyjk/index.vue"),
        meta: {
          title: "滇约驾考",
        },
      },
      {
        path: "/dyjc",
        name: "dyjc",
        component: () => import("../views/dyjc/index.vue"),
        meta: {
          title: "滇约检车",
        },
      },
      {
        path: "/dyky",
        name: "dyky",
        component: () => import("../views/dyky/index.vue"),
        meta: {
          title: "滇约客运",
        },
      },
      {
        path: "/dytz",
        name: "dytz",
        component: () => import("../views/dytz/index.vue"),
        meta: {
          title: "滇约投资",
        },
      },
      {
        path: "/dysy",
        name: "dysy",
        component: () => import("../views/dysy/index.vue"),
        meta: {
          title: "滇约速运",
        },
      },
      {
        path: "/dylyky",
        name: "dylyky",
        component: () => import("../views/lyky/index.vue"),
        meta: {
          title: "滇约旅游客运",
        },
      },
      {
        path: "/joinDycx",
        name: "joinDycx",
        component: () => import("../views/joinDycx/index.vue"),
        meta: {
          title: "加盟滇约出行",
        },
      },
      {
        path: "/joinCitys",
        name: "joinCitys",
        component: () => import("../views/joinDycx/joinCitys.vue"),
        meta: {
          title: "城市加盟",
        },
      },
      {
        path: "/longListanceJoin",
        name: "longListanceJoin",
        component: () => import("../views/joinDycx/longListanceJoin.vue"),
        meta: {
          title: "长途网约车加盟",
        },
      },
      {
        path: "/insideCity",
        name: "insideCity",
        component: () => import("../views/joinDycx/insideCity.vue"),
        meta: {
          title: "长途网约车加盟",
        },
      },
      {
        path: "/joinDycd",
        name: "joinDycd",
        component: () => import("../views/joinDycd/index.vue"),
        meta: {
          title: "加盟滇约充电",
        },
      },
      {
        path: "/joinDysy",
        name: "joinDysy",
        component: () => import("../views/joinDysy/index.vue"),
        meta: {
          title: "加盟滇约速运",
        },
      },
      {
        path: "/joinDyjk",
        name: "joinDyjk",
        component: () => import("../views/joinDyjk/index.vue"),
        meta: {
          title: "加盟滇约驾考",
        },
      },
      {
        path: "/recruit",
        name: "recruit",
        component: () => import("../views/recruit/index.vue"),
        meta: {
          title: "人才招聘",
        },
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/newsView/index.vue"),
        meta: {
          title: "新闻咨询",
        },
      },
      {
        path: "/social",
        name: "social",
        component: () => import("../views/socialvalue/index.vue"),
        meta: {
          title: "社会价值",
        },
      },
      {
        path: "/dycx",
        name: "dycx",
        component: () => import("../views/dycx/index.vue"),
        meta: {
          title: "滇约出行",
        },
      },
      {
        path: "/joinShop",
        name: "joinShop",
        component: () => import("../views/joinShop/index.vue"),
        meta: {
          title: "加盟滇约商城",
        },
      },
      {
        path: "/joinDyly",
        name: "joinDyly",
        component: () => import("../views/joinDyly/index.vue"),
        meta: {
          title: "加盟滇约旅游",
        },
      },
      {
        path: "/joinDyzc",
        name: "joinDyzc",
        component: () => import("../views/joinDyzc/index.vue"),
        meta: {
          title: "加盟滇约租车",
        },
      },
      {
        path: "/join",
        name: "join",
        component: () => import("../views/join.vue"),
        meta: {
          title: "合作加盟",
        },
      },
      {
        path: "/cooperativeAlliance",
        name: "cooperativeAlliance",
        component: () => import("../views/cooperativeAlliance/index.vue"),
        meta: {
          title: "智慧出行联盟",
        },
      },
      {
        path: "/nearbyStores",
        name: "nearbyStores",
        component: () => import("../views/nearbyStores/index.vue"),
        meta: {
          title: "附近门店",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
  window.document.title = '滇约集团——' + to.meta.title
  next()
})

export default router;

import { createApp } from "vue";
import VueAnimateOnScroll from 'vue3-animate-onscroll';
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueLazyload from "vue-lazyload";


const app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.use(VueAnimateOnScroll);
app.use(VueLazyload,{
  preLoad: 1,
  attempt: 3,
  loading: 'https://dytz-website.obs.cn-south-1.myhuaweicloud.com/homesite_forntend_v3/loading.gif',
  error: 'https://dycx-web.oss-cn-shenzhen.aliyuncs.com/media/20201221/5fe050239bce0.png',
  throttleWait: 500
})
app.mount("#app");

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0

  next()
})

router.afterEach(() => {
  if(!window.sessionStorage.firstUrl){
    window.sessionStorage.firstUrl = window.location.href
  }
  // console.log(window.sessionStorage.firstUrl);
})
// createApp(App).use(router).use(ElementUI).mount("#app");
